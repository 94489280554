<template>
    <div
        ref="anchorRef"
        class="visually-hidden"
    />
    <Popover
        ref="popoverRef"
        v-model:show="show"
        :top="topOffset"
        :left="leftOffset"
        :width="width"
        :variant="variant"
        :custom-class="customClass"
        :click-outside-bypass-ids="[clickElementId]"
        :teleport="teleport"
        v-bind="$attrs"
        @hidden="onHidden"
        @shown="onShown"
    >
        <slot />
    </Popover>
</template>

<script lang="ts" setup>
import {
    computed, nextTick, onMounted, onUnmounted, ref, useTemplateRef,
} from 'vue';
import {
    useElementBounding, useResizeObserver, useThrottleFn,
} from '@vueuse/core';
import { clamp } from 'lodash-es';
import { useWindow } from '../../composables/window';
import { Position } from '../../store/modules/globalSettings';
import { HIDE_DROPDOWNS_EVENT } from '../../../scripts/constants/events';
import dropdownEventBus from '../../Event/DropdownEventBus';
import { Variant } from './Popover/Variant';
import Popover from './Popover.vue';
import { useVisibilityObserver } from '../../composables/observer/visibilityObserver';

const popoverRef = useTemplateRef<InstanceType<typeof Popover>>('popoverRef');
const popoverHtmlElementRef = computed<HTMLElement|null>(() => popoverRef.value?.root ?? null);
const anchorRef = ref<HTMLDivElement>();

type Props = {
    variant?: Variant[keyof Variant] | null,
    target: string;
    position?: Position;
    customClass?: string;
    offset?: number;
    boundaryPadding?: number;
    autoWidth?: boolean
    buttonId?: string,
    teleport?: string | HTMLElement,
};

const props = withDefaults(defineProps<Props>(), {
    position: Position.BOTTOM_RIGHT,
    offset: 5,
    boundaryPadding: 50,
    customClass: undefined,
    variant: Variant.PRIMARY,
    autoWidth: false,
    buttonId: undefined,
});

const show = defineModel<boolean>('show', { default: false });

type Emits = {
    (event: 'shown'): void;
    (event: 'hidden'): void;
};

const emit = defineEmits<Emits>();

const topOffset = ref<number>(0);
const leftOffset = ref<number>(0);

const windowScreen = useWindow();

const targetElement = ref<HTMLElement|null>(null);

const targetBounding = useElementBounding(targetElement);

const getTargetElement = () => document.getElementById(props.target);

const clickElementId = computed(() => props.buttonId ?? props.target);

const calculateRealPosition = (): Position => {
    const popover = popoverHtmlElementRef.value;
    if (!popover) {
        return props.position;
    }

    const margin = props.offset;
    const popoverHeight = popover.offsetHeight;
    const popoverWidth = popover.offsetWidth;
    const targetY = targetBounding.top.value;
    const targetX = targetBounding.left.value;
    const targetHeight = targetBounding.height.value;
    const targetWidth = targetBounding.width.value;
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;

    const isOutOfBounds = {
        bottom: targetY + targetHeight + popoverHeight > screenHeight - margin,
        top: targetY - popoverHeight < margin,
        left: targetX - popoverWidth < margin,
        right: targetX + targetWidth + popoverWidth > screenWidth - margin,
    };

    if (
        !isOutOfBounds.bottom
        && !isOutOfBounds.top
        && !isOutOfBounds.left
        && !isOutOfBounds.right
    ) {
        return props.position;
    }

    if (!isOutOfBounds.bottom && !isOutOfBounds.left) {
        return Position.BOTTOM_LEFT;
    }

    if (!isOutOfBounds.bottom && !isOutOfBounds.right) {
        return Position.BOTTOM_LEFT;
    }

    if (!isOutOfBounds.bottom) {
        return Position.BOTTOM;
    }

    if (!isOutOfBounds.top && !isOutOfBounds.left) {
        return Position.TOP_LEFT;
    }

    if (!isOutOfBounds.top && !isOutOfBounds.right) {
        return Position.TOP_RIGHT;
    }

    if (!isOutOfBounds.top) {
        return Position.TOP;
    }

    return Position.BOTTOM;
};

const getTopOffset = (): number => {
    const realPosition: Position = calculateRealPosition();

    if (targetElement.value === null) {
        return 0;
    }

    if (!popoverHtmlElementRef.value) {
        return 0;
    }

    if ([Position.BOTTOM, Position.BOTTOM_LEFT, Position.BOTTOM_RIGHT].includes(realPosition)) {
        return targetBounding.top.value + targetBounding.height.value + props.offset;
    }

    if ([Position.TOP, Position.TOP_LEFT, Position.TOP_RIGHT].includes(realPosition)) {
        return targetBounding.top.value - popoverHtmlElementRef.value.scrollHeight + props.offset;
    }

    return 0;
};

const getLeftOffset = (): number => {
    const realPosition: Position = calculateRealPosition();

    if (!popoverHtmlElementRef.value) {
        return 0;
    }

    if (targetElement.value === null) {
        return 0;
    }

    const left = targetBounding.left.value;
    const width = targetBounding.width.value;
    const { offsetWidth } = popoverHtmlElementRef.value;

    let offsetWithoutLimits = left + width / 2 - offsetWidth / 2;

    if (realPosition === Position.BOTTOM_LEFT) {
        offsetWithoutLimits = left;
    }

    if (realPosition === Position.BOTTOM_RIGHT) {
        offsetWithoutLimits = left + width - offsetWidth;
    }

    if (realPosition === Position.TOP_LEFT) {
        offsetWithoutLimits = left;
    }

    if (realPosition === Position.TOP_RIGHT) {
        offsetWithoutLimits = left + width - offsetWidth;
    }

    const minOffset = props.boundaryPadding;
    const maxOffset = windowScreen.innerWidth.value - offsetWidth - props.boundaryPadding;

    return clamp(offsetWithoutLimits, minOffset, maxOffset);
};

const width = computed<number|undefined>(
    () => (props.autoWidth ? targetBounding.width.value : undefined),
);

const updateBoundings = () => {
    targetElement.value = getTargetElement();
    targetBounding.update();

    topOffset.value = getTopOffset();
    leftOffset.value = getLeftOffset();
};

const updateBoundingsThrottled = useThrottleFn(updateBoundings, 100, true);

const onHidden = () => {
    emit('hidden');
};

const onShown = async () => {
    emit('shown');

    await nextTick();
    updateBoundings();
};

const { stop: stopResizeObserver } = useResizeObserver(popoverHtmlElementRef, () => {
    updateBoundings();
});

useVisibilityObserver(anchorRef, {
    onHidden: () => {
        show.value = false;
    },
});

onMounted(() => {
    updateBoundings();

    window.addEventListener('resize', updateBoundingsThrottled, true);
    window.addEventListener('scroll', updateBoundingsThrottled, true);

    dropdownEventBus.on(HIDE_DROPDOWNS_EVENT, (id) => {
        if (show.value && id !== props.target) {
            show.value = false;
        }
    });
});

onUnmounted(() => {
    dropdownEventBus.off(HIDE_DROPDOWNS_EVENT);
    window.removeEventListener('resize', updateBoundingsThrottled, true);
    window.addEventListener('scroll', updateBoundingsThrottled, true);
    stopResizeObserver();
});
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/z-indexes';

.popover {
    position: absolute;
    z-index: $user-info-popup-z-index;

    max-width: 28.75rem;
    padding: 0;

    font-size: $font-size-base;

    border: 0;
    border-radius: $border-radius;
    box-shadow: var(--shadow-4);

    transition: opacity 0.2s ease;
}

.popover--primary {
    background-color: var(--theme-color-surface-primary-default);
}

.popover--secondary {
    background-color: var(--theme-color-surface-inversed-secondary);
}

.popover-enter-from,
.popover-leave-to {
    opacity: 0;
}
</style>
